<template>
  <section>
    <h2>Export Data</h2>
    <b-form-select
      v-model="selected"
      class="my-4"
      :options="options"
    ></b-form-select>
    <b-button block @click="onDownloadClick">Download</b-button>
  </section>
</template>

<script>
import sanity from "../api";
import groq from "groq";

import { Parser } from "json2csv";

const EVENTS_QUERY = groq`*[_type == "event"]{
  "slug": slug.current,
  title,
  "people": people[]->{name, richDescription,"slug": slug.current},
  richDescription,
  eventDate,
  gallery,
  "movie": movie.asset->url,
  "links": links[]{title, link},
} | order(eventDate asc)`;

const PEOPLE_QUERY = groq`*[_type == "person"] {
  name,
  "slug": slug.current,
  secretName,
  homeplace,
  yearOfBirth,
  richDescription,
  "movie": movie.asset->url,
  "links": links[]{title, link},
  gallery,
  "events": *[_type == "event" && references(^._id)] {
  	_id,
  	richDescription,
  	title,
    eventDate,
    "slug": slug.current,
  },
}`;

const LOCATIONS_QUERY = groq`*[_type == "location"] {
  "slug": slug.current,
  title,
  richDescription,
  gallery,
  "movie": movie.asset->url,
  coordinates {lat, lng},
  "links": links[]{title, link},
  "events": *[_type == "event" && references(^._id)] {
  	_id,
  	richDescription,
  	title,
    eventDate,
    "slug": slug.current,
	}
}`;

const OUTLINES_QUERY = groq`*[_type == "outline"] {
  "slug": slug.current,
  title,
  richDescription,
  gallery,
  "movie": movie.asset->url,
  "links": links[]{title, link},
  "people": people[]->{name, richDescription,"slug": slug.current},
}`;

export default {
  data() {
    return {
      options: [
        { value: null, text: "Select an entity to export" },
        {
          value: {
            query: EVENTS_QUERY,
            fields: [
              "slug",
              "title",
              "people",
              "richDescription",
              "eventDate",
              "gallery",
              "movie",
              "links",
            ],
            title: "events",
          },
          text: "Events",
        },
        {
          value: {
            query: PEOPLE_QUERY,
            fields: [
              "name",
              "slug",
              "secretName",
              "homeplace",
              "yearOfBirth",
              "richDescription",
              "movie",
              "links",
              "gallery",
              "events",
            ],
            title: "people",
          },
          text: "People",
        },
        {
          value: {
            query: LOCATIONS_QUERY,
            fields: [
              "slug",
              "title",
              "richDescription",
              "gallery",
              "movie",
              "coordinates",
              "links",
              "events",
            ],
            title: "locations",
          },
          text: "Locations",
        },
        {
          value: {
            query: OUTLINES_QUERY,
            fields: [
              "slug",
              "title",
              "richDescription",
              "gallery",
              "movie",
              "links",
              "people",
            ],
            title: "outlines",
          },
          text: "Outlines",
        },
      ],
      selected: null,
    };
  },
  methods: {
    async fetchEvents(query) {
      return await sanity.fetch(query);
    },
    onDownloadClick() {
      if (this.selected !== null) {
        this.fetchEvents(this.selected.query).then((data) => {
          const parser = new Parser({ fields: this.selected.fields });
          let csv = parser.parse(data);
          var fileURL = window.URL.createObjectURL(new Blob([csv]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `${this.selected.title}.csv`);
          document.body.appendChild(fileLink);

          fileLink.click();
        });
      }
    },
  },
};
</script>

<style></style>
