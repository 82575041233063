<template>
  <section>
    <h2 class="text-center my-4 display-4">Kontakt</h2>
    <b-form
      name="contact-form"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="contact-form" />

      <b-form-input
        class="my-3"
        name="email"
        type="email"
        placeholder="Din e-post"
        required
      ></b-form-input>

      <b-form-input
        class="my-3"
        name="name"
        type="text"
        placeholder="Din navn"
        required
      ></b-form-input>

      <b-form-input
        class="my-3"
        name="title"
        type="text"
        placeholder="Emne"
        required
      ></b-form-input>

      <b-form-textarea
        class="my-3"
        name="message"
        placeholder="Melding"
        required
      ></b-form-textarea>

      <b-button block type="submit" variant="primary">Send</b-button>
    </b-form>
  </section>
</template>
<script>
export default {
  name: "ContactForm",
};
</script>
