<template>
  <main>
    <b-spinner v-if="loading" label="Loading..."></b-spinner>
    <template v-else>
      <section>
        <h2 class="text-center my-4 display-4">Personutforsker</h2>
        <h3 class="my-3">Velg en person</h3>
        <b-form-select
          v-model="selectedPersonName"
          :options="selectOptions"
          @change="onSelectChange"
        ></b-form-select>
        <hr class="my-4" />
      </section>

      <article>
        <section>
          <h2 class="my-4">
            {{ selectedPerson.name }}
            <template v-if="selectedPerson.secretName">
              ({{ selectedPerson.secretName }})
            </template>
            <template v-if="selectedPerson.yearOfBirth">
              – født i
              {{ selectedPerson.yearOfBirth }}
            </template>
          </h2>
          <hr class="my-4" />
        </section>

        <section
          v-if="selectedPerson.richDescription"
          style="white-space: pre-wrap"
        >
          <block-content :blocks="selectedPerson.richDescription" />
          <hr class="my-4" />
        </section>

        <section v-if="events">
          <h2 class="my-4">Hendelser til en person</h2>
          <b-table striped hover :items="events">
            <template #cell(lenke)="data">
              <router-link
                :to="{ name: 'timeline', params: { event: data.item.lenke } }"
                >Se mer</router-link
              >
            </template>
          </b-table>
          <hr class="my-4" />
        </section>

        <section v-if="gallery">
          <h2 class="my-4">Galleri</h2>
          <b-carousel
            id="carousel"
            :interval="4000"
            controls
            indicators
            background="#ababab"
            img-width="1280"
            img-height="720"
            style="text-shadow: 1px 1px 2px #333"
          >
            <b-carousel-slide
              v-for="slide in gallery"
              :key="slide.key"
              :img-src="slide.image"
            ></b-carousel-slide>
          </b-carousel>
          <hr class="my-4" />
        </section>

        <section v-if="selectedPerson.movie">
          <video controls class="my-4">
            <source :src="selectedPerson.movie" type="video/mp4" />
          </video>
          <hr class="my-4" />
        </section>

        <section v-if="links">
          <h2 class="my-4">Nyttige lenker</h2>
          <b-table striped hover :items="links">
            <template #cell(lenke)="data">
              <a :href="data.item.lenke">Se mer</a>
            </template>
          </b-table>
          <hr class="my-4" />
        </section>
      </article>
    </template>
  </main>
</template>

<script>
import sanity from "../api";
import imageUrlBuilder from "@sanity/image-url";
import groq from "groq";

const imageBuilder = imageUrlBuilder(sanity);

const query = groq`*[_type == "person"] {
  _id,
  name,
  "slug": slug.current,
  secretName,
  homeplace,
  yearOfBirth,
  richDescription,
  "movie": movie.asset->url,
  "links": links[]{title, link},
  gallery,
  "events": *[_type == "event" && references(^._id)] {
  	_id,
  	description,
  	title,
    eventDate,
    "slug": slug.current,
  },
} | order(name asc)`;

export default {
  name: "PeopleExplorer",
  data() {
    return {
      people: [],
      selectedPersonName: "",
      loading: false,
    };
  },
  computed: {
    selectOptions() {
      return this.people.map((person) => {
        return {
          value: person.name,
          text: person.name,
        };
      });
    },
    selectedPerson() {
      return (
        this.people.filter(
          (person) => person.name == this.selectedPersonName
        )[0] || {}
      );
    },
    events() {
      return this.selectedPerson.events && this.selectedPerson.events.length > 0
        ? this.selectedPerson.events.map((event) => {
            return {
              tittel: event.title,
              dato: event.eventDate,
              lenke: event.slug,
            };
          })
        : null;
    },
    gallery() {
      return this.selectedPerson.gallery
        ? this.selectedPerson.gallery.map((slide) => {
            return {
              image: imageBuilder
                .image(slide)
                .ignoreImageParams()
                .width(1280)
                .height(720)
                .fit("fill")
                .bg("000000")
                .url(),
              key: slide._key,
            };
          })
        : null;
    },
    links() {
      return this.selectedPerson.links
        ? this.selectedPerson.links.map((link) => {
            return {
              tittel: link.title,
              lenke: link.link,
            };
          })
        : null;
    },
  },
  created() {
    this.loading = true;

    this.fetchPeople().then((people) => {
      this.people = people;

      if (this.$route.params.person) {
        this.selectedPersonName = this.getNameBySlug(this.$route.params.person);
      } else {
        this.selectedPersonName = this.people[0].name;
        this.$router.replace({
          name: "people",
          params: { person: this.selectedPerson.slug },
        });
      }

      this.loading = false;
    });
  },
  methods: {
    async fetchPeople() {
      return await sanity.fetch(query);
    },
    getNameBySlug(slug) {
      return this.people.filter((person) => person.slug == slug)[0].name;
    },
    onSelectChange() {
      this.$router.push({
        name: "people",
        params: { person: this.selectedPerson.slug },
      });
    },
  },
};
</script>
