import Vue from "vue";

// BootstrapVue

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

import "./bootstrap.scss";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

// Leaflet styles

import "leaflet/dist/leaflet.css";

// Leaflet missing icons quickfix

import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// Sanity blocks

import BlockContent from "sanity-blocks-vue-component";
Vue.component("block-content", BlockContent);

// Main components

import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
