<template>
  <article>
    <section>
      <h2 class="text-center mt-5 display-4">Sted</h2>
      <h3 class="text-center mt-3">{{ location.title }}</h3>
      <hr class="my-4" />
    </section>

    <section v-if="location.richDescription" style="white-space: pre-wrap">
      <h2 class="my-4">Beskrivelse</h2>
      <block-content :blocks="location.richDescription" />
      <hr class="my-4" />
    </section>

    <section v-if="events">
      <h2 class="my-4">Hendelser som skjedde her</h2>
      <b-table striped hover :items="events">
        <template #cell(lenke)="data">
          <router-link
            :to="{ name: 'timeline', params: { event: data.item.lenke } }"
            >Se mer</router-link
          >
        </template>
      </b-table>
      <hr class="my-4" />
    </section>

    <section v-if="people">
      <h2 class="my-4">Mennesker her</h2>
      <b-table striped hover :items="people">
        <template #cell(lenke)="data">
          <router-link
            :to="{ name: 'people', params: { person: data.item.lenke } }"
            >Se mer</router-link
          >
        </template>
      </b-table>
      <hr class="my-4" />
    </section>

    <section v-if="gallery">
      <h2 class="my-4">Galleri</h2>
      <b-carousel
        id="carousel"
        :interval="4000"
        controls
        indicators
        background="#ababab"
        img-width="1280"
        img-height="720"
        style="text-shadow: 1px 1px 2px #333"
      >
        <b-carousel-slide
          v-for="slide in gallery"
          :key="slide.key"
          :img-src="slide.image"
        ></b-carousel-slide>
      </b-carousel>
      <hr class="my-4" />
    </section>

    <section v-if="location.movie">
      <video controls class="my-4">
        <source :src="location.movie" type="video/mp4" />
      </video>
      <hr class="my-4" />
    </section>

    <section v-if="links">
      <h2 class="my-4">Nyttige lenker</h2>
      <b-table striped hover :items="links">
        <template #cell(lenke)="data">
          <a :href="data.item.lenke">Se mer</a>
        </template>
      </b-table>
      <hr class="my-4" />
    </section>
  </article>
</template>

<script>
import sanity from "../api";
import imageUrlBuilder from "@sanity/image-url";

const imageBuilder = imageUrlBuilder(sanity);

export default {
  name: "Location",
  props: { location: { required: true, type: Object } },
  computed: {
    events() {
      return this.location.events && this.location.events.length > 0
        ? this.location.events.map((event) => {
            return {
              tittel: event.title,
              dato: event.eventDate,
              lenke: event.slug,
            };
          })
        : null;
    },
    gallery() {
      return this.location.gallery
        ? this.location.gallery.map((slide) => {
            return {
              image: imageBuilder
                .image(slide)
                .ignoreImageParams()
                .width(1280)
                .height(720)
                .fit("fill")
                .bg("000000")
                .url(),
              key: slide._key,
            };
          })
        : null;
    },
    people() {
      return this.location.people && this.location.people.length > 0
        ? this.location.people.map((person) => {
            return {
              navn: person.name,
              lenke: person.slug,
            };
          })
        : null;
    },
    links() {
      return this.location.links
        ? this.location.links.map((link) => {
            return {
              tittel: link.title,
              lenke: link.link,
            };
          })
        : null;
    },
  },
};
</script>
