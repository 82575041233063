<template>
  <main>
    <b-spinner v-if="loading" label="Loading..."></b-spinner>
    <template v-else>
      <section>
        <h2 class="text-center my-4 display-4">Annen informasjon</h2>
        <h3 class="my-3">Velg en</h3>
        <b-form-select
          v-model="selectedOutlineTitle"
          :options="selectOptions"
          @change="onSelectChange"
        ></b-form-select>
        <hr class="my-4" />
      </section>
    </template>
    <outline v-if="!loading" :outline="selectedOutline"></outline>
  </main>
</template>

<script>
import sanity from "../api";
import groq from "groq";

const query = groq`*[_type == "outline"]{
  _id,
  "slug": slug.current,
  title,
  "people": people[]->{name, description,"slug": slug.current},
  richDescription,
  gallery,
  "movie": movie.asset->url,
  "links": links[]{title, link},
} | order(title asc)`;

import Outline from "../components/Outline";

export default {
  name: "OutlineExplorer",
  components: {
    Outline,
  },
  data() {
    return {
      outlines: [],
      selectedOutlineTitle: "",
      loading: false,
    };
  },
  computed: {
    selectOptions() {
      return this.outlines.map((outline) => {
        return {
          value: outline.title,
          text: outline.title,
        };
      });
    },
    selectedOutline() {
      return (
        this.outlines.filter(
          (outline) => outline.title == this.selectedOutlineTitle
        )[0] || {}
      );
    },
  },
  created() {
    this.loading = true;

    this.fetchOutlines().then((outlines) => {
      this.outlines = outlines;

      if (this.$route.params.outline) {
        this.selectedOutlineTitle = this.getTitleySlug(
          this.$route.params.outline
        );
      } else {
        this.selectedOutlineTitle = this.outlines[0].title;
        this.$router.replace({
          name: "outlines",
          params: { outline: this.selectedOutline.slug },
        });
      }

      this.loading = false;
    });
  },
  methods: {
    async fetchOutlines() {
      return await sanity.fetch(query);
    },
    getTitleySlug(slug) {
      return this.outlines.filter((outline) => outline.slug == slug)[0].title;
    },
    onSelectChange() {
      this.$router.push({
        name: "outlines",
        params: { outline: this.selectedOutline.slug },
      });
    },
  },
};
</script>
