<template>
  <main>
    <b-spinner v-if="loading" label="Loading..."></b-spinner>
    <section>
      <h2 v-if="!loading" class="text-center my-4 display-4">
        Tidslinjeutforsker
      </h2>
      <!-- This cointainer should be always rendered since it is used in asynchronous code -->
      <div id="timeline" ref="timeline"></div>
    </section>
    <event v-if="!loading" :event="currentEvent"></event>
  </main>
</template>

<script>
import sanity from "../api";
import imageUrlBuilder from "@sanity/image-url";
import groq from "groq";

const imageBuilder = imageUrlBuilder(sanity);

const query = groq`*[_type == "event"]{
  _id,
  "slug": slug.current,
  title,
  "people": people[]->{name, description,"slug": slug.current},
  location -> {title,"slug": slug.current}, 
  locationFrom -> {title,"slug": slug.current}, 
  richDescription ,
  eventDate,
  gallery,
  "movie": movie.asset->url,
  "links": links[]{title, link},
} | order(eventDate asc)`;

import { Timeline } from "@knight-lab/timelinejs/src/js";

import Event from "../components/Event";

export default {
  name: "TimelineExplorer",
  components: {
    Event,
  },
  data() {
    return {
      events: [],
      currentEventSlug: "",
      loading: false,
    };
  },
  computed: {
    currentEvent() {
      return this.events.filter(
        (event) => event.slug == this.currentEventSlug
      )[0];
    },
    timeline() {
      return {
        events: this.events.map((event) => {
          let eventDate = new Date(event.eventDate);
          return {
            start_date: {
              year: eventDate.getFullYear(),
              month: eventDate.getMonth() + 1, // Quickfix since JS date counts months from 0
              day: eventDate.getDate(),
            },
            text: {
              headline: event.title,
            },
            media: {
              url: event.gallery
                ? imageBuilder
                    .image(event.gallery[0])
                    .ignoreImageParams()
                    .width(1280)
                    .height(720)
                    .fit("fill")
                    .bg("ffffff")
                    .url()
                : null,
            },
            unique_id: event.slug,
          };
        }),
      };
    },
  },
  created() {
    this.loading = true;

    this.fetchEvents().then((events) => {
      this.events = events;

      let timeline = new Timeline(this.$refs.timeline, this.timeline, {
        language: "no",
      });

      if (this.$route.params.event) {
        this.changeEvent(this.$route.params.event);
        timeline.goToId(this.currentEventSlug);
      } else {
        this.changeEvent(timeline.getData(0).unique_id);
        this.$router.replace({
          name: "timeline",
          params: { event: this.currentEvent.slug },
        });
      }

      timeline.on("change", (data) => {
        this.changeEvent(data.unique_id);
        this.$router.push({
          name: "timeline",
          params: { event: this.currentEvent.slug },
        });
      });

      this.loading = false;
    });
  },
  methods: {
    async fetchEvents() {
      return await sanity.fetch(query);
    },
    changeEvent(newEventSlug) {
      if (
        this.events.filter((event) => event.slug == newEventSlug).length === 1
      ) {
        this.currentEventSlug = newEventSlug;
      } else {
        this.$router.replace({ name: "not-found" });
      }
    },
  },
};
</script>

<style scoped>
#timeline {
  width: 100%;
  height: 600px;
}
</style>
