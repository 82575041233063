<template>
  <main>
    <b-spinner v-if="loading" label="Loading..."></b-spinner>
    <template v-else>
      <h2 class="text-center my-4 display-4">Kartutforsker</h2>
      <cartographic-map
        :points="points"
        :initial-zoom="initialZoom"
        :initial-center="initialCenter"
        :url="url"
        @marker-click="onMarkerClick"
      ></cartographic-map>
      <location v-if="currentLocation" :location="currentLocation"></location>
    </template>
  </main>
</template>

<script>
import sanity from "../api";
import groq from "groq";

const query = groq`*[_type == "location"] {
  _id,
  "slug": slug.current,
  title,
  richDescription,
  gallery,
  "movie": movie.asset->url,
  coordinates {lat, lng},
  "links": links[]{title, link},
  "people": people[]->{name, description,"slug": slug.current},
  "events": *[_type == "event" && references(^._id)] {
  	_id,
  	description,
  	title,
    eventDate,
    "slug": slug.current,
	}
}`;

import Location from "../components/Location";
import Map from "../components/Map";

export default {
  name: "MapExplorer",
  components: {
    Location,
    "cartographic-map": Map,
  },
  data() {
    return {
      locations: [],
      currentLocationSlug: "",
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      initialZoom: 8,
      initialCenter: [0, 0],
      loading: false,
    };
  },
  computed: {
    currentLocation() {
      return this.locations.filter(
        (location) => location.slug == this.currentLocationSlug
      )[0];
    },
    points() {
      return this.locations.map((location) => {
        return {
          slug: location.slug,
          radius: 2500,
          center: [location.coordinates.lat, location.coordinates.lng],
          color: "red",
          title: location.title,
        };
      });
    },
  },
  created() {
    this.loading = true;

    this.fetchLocations().then((locations) => {
      this.locations = locations;

      if (this.$route.params.location) {
        this.changeLocation(this.$route.params.location);
      } else {
        const STARTING_LOCATION_SLUG = "ambassaden-hq1";
        this.changeLocation(STARTING_LOCATION_SLUG);

        this.$router.replace({
          name: "map",
          params: { location: this.currentLocation.slug },
        });
      }

      this.initialCenter = [
        this.currentLocation.coordinates.lat,
        this.currentLocation.coordinates.lng,
      ];

      this.loading = false;
    });
  },
  methods: {
    async fetchLocations() {
      return await sanity.fetch(query);
    },
    changeLocation(newLocationSlug) {
      if (
        this.locations.filter((location) => location.slug == newLocationSlug)
          .length === 1
      ) {
        this.currentLocationSlug = newLocationSlug;
      } else {
        this.$router.replace({ name: "not-found" });
      }
    },
    onMarkerClick(point) {
      this.changeLocation(point.slug);
      this.$router
        .push({
          name: "map",
          params: { location: this.currentLocationSlug },
        })
        .catch(() => {});
    },
  },
};
</script>
