<template>
  <article>
    <section>
      <h2 class="text-center mt-5 display-4">Hendelse</h2>
      <h3 class="text-center mt-3">{{ event.title }} – {{ date }}</h3>
      <hr class="my-4" />
    </section>

    <section v-if="event.richDescription" style="white-space: pre-wrap">
      <h2 class="my-4">Beskrivelse</h2>
      <block-content
        :blocks="event.richDescription"
        :serializers="serializers"
      />
      <hr class="my-4" />
    </section>

    <section>
      <h2 class="my-4">Steder</h2>
      <b-table striped hover :items="locations">
        <template #cell(lenke)="data">
          <router-link
            :to="{ name: 'map', params: { location: data.item.lenke } }"
            >Se mer</router-link
          >
        </template>
      </b-table>
      <hr class="my-4" />
    </section>

    <section v-if="people">
      <h2 class="my-4">Mennesker involvert</h2>
      <b-table striped hover :items="people">
        <template #cell(lenke)="data">
          <router-link
            :to="{ name: 'people', params: { person: data.item.lenke } }"
            >Se mer</router-link
          >
        </template>
      </b-table>
      <hr class="my-4" />
    </section>

    <section v-if="gallery">
      <h2 class="my-4">Galleri</h2>
      <b-carousel
        id="carousel"
        :interval="4000"
        controls
        indicators
        background="#ababab"
        img-width="1280"
        img-height="720"
        style="text-shadow: 1px 1px 2px #333"
      >
        <b-carousel-slide
          v-for="slide in gallery"
          :key="slide.key"
          :img-src="slide.image"
        ></b-carousel-slide>
      </b-carousel>
    </section>

    <section v-if="event.movie">
      <video controls class="my-4">
        <source :src="event.movie" type="video/mp4" />
      </video>
      <hr class="my-4" />
    </section>

    <section v-if="links">
      <h2 class="my-4">Nyttige lenker</h2>
      <b-table striped hover :items="links">
        <template #cell(lenke)="data">
          <a :href="data.item.lenke">Se mer</a>
        </template>
      </b-table>
      <hr class="my-4" />
    </section>
  </article>
</template>

<script>
import sanity from "../api";
import imageUrlBuilder from "@sanity/image-url";

const imageBuilder = imageUrlBuilder(sanity);

export default {
  name: "Event",
  props: { event: { type: Object, required: true } },
  computed: {
    links() {
      return this.event.links
        ? this.event.links.map((link) => {
            return {
              tittel: link.title,
              lenke: link.link,
            };
          })
        : null;
    },
    people() {
      return this.event.people && this.event.people.length > 0
        ? this.event.people.map((person) => {
            return {
              navn: person.name,
              lenke: person.slug,
            };
          })
        : null;
    },
    locations() {
      if (this.event.locationFrom) {
        return [
          {
            tittel: "Sendt fra: " + this.event.locationFrom.title,
            lenke: this.event.locationFrom.slug,
          },
          {
            tittel: this.event.location.title,
            lenke: this.event.location.slug,
          },
        ];
      } else {
        return [
          {
            tittel: this.event.location.title,
            lenke: this.event.location.slug,
          },
        ];
      }
    },
    gallery() {
      return this.event.gallery
        ? this.event.gallery.map((slide) => {
            return {
              image: imageBuilder
                .image(slide)
                .ignoreImageParams()
                .width(1280)
                .height(720)
                .fit("fill")
                .bg("000000")
                .url(),
              key: slide._key,
            };
          })
        : null;
    },
    date() {
      var options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return new Date(this.event.eventDate).toLocaleDateString(
        "nb-NO",
        options
      );
    },
  },
};
</script>

<style scoped>
video {
  width: 100%;
  height: auto;
}
</style>
