<template>
  <main>
    <b-jumbotron
      header="Base Elg"
      lead="Velkommen til nettsiden! Velg en visning fra menyen for å begynne"
    >
      <iframe
        src="https://player.vimeo.com/video/693550948?h=d471a9ec56"
        width="100%"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
      ></iframe>
    </b-jumbotron>
    <section>
      <b-card-group deck>
        <b-card
          :img-src="require('@/assets/timeline.gif')"
          img-alt="Tidslinje"
          img-top
          title="Tidslinje"
        >
          <b-card-text>
            Her vil du finne hendelsene utfra den når ting skjedde. Fra denne
            kan du gå videre til Steder og Personer, og du har hele tiden menyen
            på toppen for å gå til andre sider.
            <br />
            MERK: Alle linker åpnes i samme vindu, slik at når du har vært på
            eks. Nasjonalbiblioteket - så må du trykke TILBAKE for å komme på
            siden igjen.
          </b-card-text>
          <router-link v-slot="{ href, navigate }" to="/timeline">
            <b-button :href="href" variant="primary" block @click="navigate">
              Gå til tidslinje
            </b-button>
          </router-link>
        </b-card>

        <b-card
          :img-src="require('@/assets/map.gif')"
          img-alt="Kart"
          img-top
          title="Kart"
        >
          <b-card-text>
            Her vil du finne de steder som basen inneholder, alt fra flyplasser
            i England til slippsteder og lokaliteter til Base Elg i Sør-Aurdal
            og Flå / Ringerike. Basegrensene fulgte ikke så nøye fylkes eller
            kommunegrenser. Det kan og være med steder og hendelser i områder
            som grenser opp mot Base Elg.
            <br />
            MERK: Alle linker åpnes i samme vindu, slik at når du har vært på
            eks. Nasjonalbiblioteket - så må du trykke TILBAKE for å komme på
            siden igjen.
          </b-card-text>
          <router-link v-slot="{ href, navigate }" to="/map">
            <b-button :href="href" variant="primary" block @click="navigate">
              Gå til kart
            </b-button>
          </router-link>
        </b-card>

        <b-card
          :img-src="require('@/assets/people.gif')"
          img-alt="Personer"
          img-top
          title="Personer"
        >
          <b-card-text>
            Her er det laget en liste over de personer man kjenner til var med i
            en eller annen sammenheng i området. Her kan det mangle personer, og
            det kan være feil informasjon om personer. Ser du noe feil, ta
            kontakt i kontaktskjema så vil vi forsøke å rette opp.
            <br />
            MERK: Alle linker åpnes i samme vindu, slik at når du har vært på
            eks. Nasjonalbiblioteket - så må du trykke TILBAKE for å komme på
            siden igjen.
          </b-card-text>
          <router-link v-slot="{ href, navigate }" to="/people">
            <b-button :href="href" variant="primary" block @click="navigate">
              Gå til personer
            </b-button>
          </router-link>
        </b-card>

        <b-card
          :img-src="require('@/assets/outlines.gif')"
          img-alt="Annen informasjon"
          img-top
          title="Annen informasjon"
        >
          <b-card-text>
            "Her vil du finne info om utstyr - slipp - forhistorie, etc - som
            ikke passer inn i tidslinje eller kart.
            <br />
            MERK: Alle linker åpnes i samme vindu, slik at når du har vært på
            eks. Nasjonalbiblioteket - så må du trykke TILBAKE for å komme på
            siden igjen."
          </b-card-text>
          <router-link v-slot="{ href, navigate }" to="/outlines">
            <b-button :href="href" variant="primary" block @click="navigate">
              Gå til annen informasjon
            </b-button>
          </router-link>
        </b-card>
      </b-card-group>
    </section>
    <partners></partners>
  </main>
</template>

<script>
import Partners from "../components/Partners";

export default {
  name: "Home",
  components: {
    Partners,
  },
};
</script>

<style>
iframe {
  aspect-ratio: 16 / 9;
}
</style>
