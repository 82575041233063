var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('b-jumbotron',{attrs:{"header":"Base Elg","lead":"Velkommen til nettsiden! Velg en visning fra menyen for å begynne"}},[_c('iframe',{attrs:{"src":"https://player.vimeo.com/video/693550948?h=d471a9ec56","width":"100%","frameborder":"0","allow":"autoplay; fullscreen; picture-in-picture","allowfullscreen":""}})]),_c('section',[_c('b-card-group',{attrs:{"deck":""}},[_c('b-card',{attrs:{"img-src":require('@/assets/timeline.gif'),"img-alt":"Tidslinje","img-top":"","title":"Tidslinje"}},[_c('b-card-text',[_vm._v(" Her vil du finne hendelsene utfra den når ting skjedde. Fra denne kan du gå videre til Steder og Personer, og du har hele tiden menyen på toppen for å gå til andre sider. "),_c('br'),_vm._v(" MERK: Alle linker åpnes i samme vindu, slik at når du har vært på eks. Nasjonalbiblioteket - så må du trykke TILBAKE for å komme på siden igjen. ")]),_c('router-link',{attrs:{"to":"/timeline"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('b-button',{attrs:{"href":href,"variant":"primary","block":""},on:{"click":navigate}},[_vm._v(" Gå til tidslinje ")])]}}])})],1),_c('b-card',{attrs:{"img-src":require('@/assets/map.gif'),"img-alt":"Kart","img-top":"","title":"Kart"}},[_c('b-card-text',[_vm._v(" Her vil du finne de steder som basen inneholder, alt fra flyplasser i England til slippsteder og lokaliteter til Base Elg i Sør-Aurdal og Flå / Ringerike. Basegrensene fulgte ikke så nøye fylkes eller kommunegrenser. Det kan og være med steder og hendelser i områder som grenser opp mot Base Elg. "),_c('br'),_vm._v(" MERK: Alle linker åpnes i samme vindu, slik at når du har vært på eks. Nasjonalbiblioteket - så må du trykke TILBAKE for å komme på siden igjen. ")]),_c('router-link',{attrs:{"to":"/map"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('b-button',{attrs:{"href":href,"variant":"primary","block":""},on:{"click":navigate}},[_vm._v(" Gå til kart ")])]}}])})],1),_c('b-card',{attrs:{"img-src":require('@/assets/people.gif'),"img-alt":"Personer","img-top":"","title":"Personer"}},[_c('b-card-text',[_vm._v(" Her er det laget en liste over de personer man kjenner til var med i en eller annen sammenheng i området. Her kan det mangle personer, og det kan være feil informasjon om personer. Ser du noe feil, ta kontakt i kontaktskjema så vil vi forsøke å rette opp. "),_c('br'),_vm._v(" MERK: Alle linker åpnes i samme vindu, slik at når du har vært på eks. Nasjonalbiblioteket - så må du trykke TILBAKE for å komme på siden igjen. ")]),_c('router-link',{attrs:{"to":"/people"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('b-button',{attrs:{"href":href,"variant":"primary","block":""},on:{"click":navigate}},[_vm._v(" Gå til personer ")])]}}])})],1),_c('b-card',{attrs:{"img-src":require('@/assets/outlines.gif'),"img-alt":"Annen informasjon","img-top":"","title":"Annen informasjon"}},[_c('b-card-text',[_vm._v(" \"Her vil du finne info om utstyr - slipp - forhistorie, etc - som ikke passer inn i tidslinje eller kart. "),_c('br'),_vm._v(" MERK: Alle linker åpnes i samme vindu, slik at når du har vært på eks. Nasjonalbiblioteket - så må du trykke TILBAKE for å komme på siden igjen.\" ")]),_c('router-link',{attrs:{"to":"/outlines"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('b-button',{attrs:{"href":href,"variant":"primary","block":""},on:{"click":navigate}},[_vm._v(" Gå til annen informasjon ")])]}}])})],1)],1)],1),_c('partners')],1)}
var staticRenderFns = []

export { render, staticRenderFns }