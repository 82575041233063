<template>
  <main>
    <h2 class="text-center my-4 display-4">Om oss</h2>
    <block-content :blocks="blocks" />
    <partners></partners>
    <contact-form></contact-form>
  </main>
</template>

<script>
import sanity from "../api";
import groq from "groq";

const query = groq`*[_type == "aboutUs"] {
  description
}`;

import ContactForm from "../components/ContactForm";
import Partners from "../components/Partners";

export default {
  components: {
    ContactForm,
    Partners,
  },
  data() {
    return {
      blocks: [],
    };
  },
  created() {
    sanity.fetch(query).then((data) => {
      this.blocks = data[0].description;
    });
  },
};
</script>

<style></style>
