<template>
  <section>
    <l-map id="map" :zoom="zoom" :center="center">
      <l-tile-layer :url="url"></l-tile-layer>
      <l-circle-marker
        v-for="point in points"
        :key="point.slug"
        :lat-lng="point.center"
        @click="$emit('marker-click', point)"
      >
        <l-tooltip>
          {{ point.title }}
        </l-tooltip>
      </l-circle-marker>
    </l-map>
  </section>
</template>

<script>
import { LMap, LTileLayer, LTooltip, LCircleMarker } from "vue2-leaflet";

export default {
  name: "Map",
  components: {
    LMap,
    LTileLayer,
    LTooltip,
    LCircleMarker,
  },
  props: {
    initialZoom: { required: true, type: Number },
    initialCenter: { required: true, type: Array },
    url: { required: true, type: String },
    points: { required: true, type: Array },
  },
  data() {
    return {
      zoom: this.initialZoom,
      center: this.initialCenter,
    };
  },
};
</script>

<style scoped>
#map {
  height: 350px;
}
</style>
