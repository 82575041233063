<template>
  <section v-if="partners.length > 0">
    <h2 class="text-center my-4 display-4">Våre Støttespillere</h2>
    <b-card-group deck>
      <b-card
        v-for="partner in partners"
        :key="partner.title"
        :img-src="partner.image"
        img-alt="Image"
        img-top
      >
        <b-card-title class="text-center" title-tag="h5">
          {{ partner.title }}
        </b-card-title>
        <b-card-text class="text-center">{{ partner.description }}</b-card-text>
      </b-card>
    </b-card-group>
  </section>
</template>

<script>
import sanity from "../api";
import groq from "groq";

const partnerQuery = groq`*[_type == "partner"] {
  title,
  description,
  "image": image.asset->url,
}`;

export default {
  name: "Partners",
  data() {
    return {
      partners: [],
    };
  },
  created() {
    sanity.fetch(partnerQuery).then((data) => {
      this.partners = data;
    });
  },
};
</script>

<style></style>
