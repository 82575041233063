<template>
  <main>
    <h2 class="text-center my-4 display-4">Filmen</h2>
    <p class="text-center">
      <em>
        Fallskjermer over Vassfaret - Historier om Base Elg <br />
        Foto: Martin B. Strøm Kjell Eriksen <br />
        Musikk og lydmix: Bjørnar Bjørnstad Manus og Regi Cato Skimten Storengen
        <br />
        Produsent: Kjell Eriksen <br />
        AS Videomaker 2022 <br />
      </em>
    </p>
    <iframe
      src="https://player.vimeo.com/video/693550948?h=d471a9ec56"
      width="100%"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>
  </main>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
iframe {
  aspect-ratio: 16 / 9;
}
</style>
