import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import TimelineExplorer from "../views/TimelineExplorer.vue";
import PeopleExplorer from "../views/PeopleExplorer.vue";
import MapExplorer from "../views/MapExplorer.vue";
import OutlineExplorer from "../views/OutlineExplorer";
import NotFound from "../views/NotFound.vue";
import Export from "../views/Export.vue";
import AboutUs from "../views/AboutUs.vue";
import Movie from "../views/Movie.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/timeline/:event?",
    name: "timeline",
    component: TimelineExplorer,
  },
  {
    path: "/map/:location?",
    name: "map",
    component: MapExplorer,
  },
  {
    path: "/people/:person?",
    name: "people",
    component: PeopleExplorer,
  },
  {
    path: "/outlines/:outline?",
    name: "outlines",
    component: OutlineExplorer,
  },
  {
    path: "/export",
    name: "export",
    component: Export,
  },
  {
    path: "/about",
    name: "about",
    component: AboutUs,
  },
  {
    path: "/movie",
    name: "movie",
    component: Movie,
  },
  {
    path: "*",
    name: "not-found",
    component: NotFound,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
